import type { CustomError } from 'monitoring/contexts/DynatraceProvider'

export const reportCustomErrorUtil = ({
	key,
	message,
	hint,
	parentingInfo,
}: CustomError) => {
	if (window?.dtrum) {
		window.dtrum.reportCustomError(key, message, hint, parentingInfo)
	}
}
